<template>
  <el-container class="container">
    <el-aside class="left-brief-list">
      <div class="logo-wrap">
        <img class="logo-sm" src="../assets/logo.png">
        <div class="logo-title">AD Go</div>
      </div>
      <div class="new-brief" @click="reset">〉NEW brief</div>
      <div class="brief-list">
        <div class="brief-item" v-for="(brief_item,index) in brief_list_c" :key="index" @click="selectBrief(index)"
             :class="{'active':brief_item.is_active}">
          <span class="brief-title" :title="brief_item.briefName">{{ brief_item.briefName }}</span>
          <i class="el-icon-edit" v-if="current_brief_id==brief_item.id"
             @click="openRenameChatDialog(brief_item)"></i>
          <i class="el-icon-delete" v-if="current_brief_id==brief_item.id"
             @click="openDeleteChatDialog(brief_item.id)"></i>
        </div>
      </div>
      <div>
        <el-dropdown trigger="click" class="username-dropdown" @command="dropDownClick">
          <span class="el-dropdown-link">
            {{ user_info['nickName'] }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-s-goods" command="course">{{
                $t('sidebar.dropdown.course')
              }}
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-user-solid" command="team">{{
                $t('sidebar.dropdown.team')
              }}
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-collection" command="usage">{{
                $t('sidebar.dropdown.usage')
              }}
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-s-tools" command="settings">{{
                $t('sidebar.dropdown.settings')
              }}
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-right" divided command="logout">{{
                $t('sidebar.dropdown.logout')
              }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="my-footer">{{ $t('home.footer') }}</div>
    </el-aside>
    <router-view></router-view>
    <el-dialog
        class="course-modal"
        :title="$t('dialog.course.title')"
        :visible.sync="v_course_dlg"
        width="576"
    >
      <el-row :gutter="40">
        <el-col :span="12">
          <p class="ft18"><i class="el-icon-star-on mr20"
                             style="color: rgb(16,163,127)"></i>{{ $t('dialog.course.preview.name') }}</p>
          <p class="ft18">{{ $t('dialog.course.preview.price') }}</p>
          <p v-if="rel_dept_product['productId']===1">
            <el-button type="info" disabled style="width: 100%">{{ $t('dialog.course.button.current') }}</el-button>
          </p>
          <p v-if="rel_dept_product['productId']===2">
            <el-button type="info" disabled style="width: 100%">{{ $t('dialog.course.button.disable') }}</el-button>
          </p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.preview.feature1') }}</p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.preview.feature2') }}</p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.preview.feature3') }}</p>
        </el-col>
        <el-col :span="12">
          <p class="ft18"><i class="el-icon-star-on mr20"
                             style="color: rgb(0,102,222)"></i>{{ $t('dialog.course.standard.name') }}</p>
          <p class="ft18">{{ $t('dialog.course.standard.price') }}</p>
          <p v-if="rel_dept_product['productId']===2">
            <el-button type="info" disabled style="width: 100%">{{ $t('dialog.course.button.current') }}</el-button>
          </p>
          <p v-if="rel_dept_product['productId']===1">
            <el-button type="primary" style="width: 100%">{{ $t('dialog.course.button.upgrade') }}</el-button>
          </p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.standard.feature1') }}</p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.standard.feature2') }}</p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.standard.feature3') }}</p>
          <p><i class="el-icon-check mr20"></i>{{ $t('dialog.course.standard.feature4') }}</p>
          <p>{{ $t('dialog.course.standard.notice') }}</p>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="font-size: 0.14rem; text-align: center">
        {{ $t('dialog.course.footer') }}<span style="text-decoration: underline">Joe.fang@aice-ai.com</span>
      </div>
    </el-dialog>
    <el-dialog
        class="settings-modal"
        :title="$t('dialog.settings.title')"
        :visible.sync="v_settings_dlg"
        width="576"
    >
      <el-tabs tab-position="left">
        <el-tab-pane label="通用">
          <p>{{ $t('dialog.settings.general.username') }}{{ user_info['userName'] }}</p>
          <hr class="bg-grey">
          <p>{{ $t('dialog.settings.general.email') }}{{ user_info['email'] }}</p>
          <hr class="bg-grey">
          <p>{{ $t('dialog.settings.general.locale') }}
            <el-select v-model="lang_value">
              <el-option
                  v-for="item in lang_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </p>
          <hr class="bg-grey">
          <p>{{ $t('dialog.settings.general.others') }} </p>
          <p v-for="item in others" :key="item.userId">{{ item.nickName }}</p>
          <hr class="bg-grey">
          <p>{{ $t('dialog.settings.general.delete') }}
            <el-button type="danger" @click="deleteAll">{{ $t('dialog.settings.general.delete_button') }}</el-button>
          </p>

        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </el-container>
</template>

<script>
import {EventBus} from '../utils/event-bus'

export default {
  name: "Home",
  data: function () {
    return {
      brief_list: [],
      current_brief_id: 0,
      v_course_dlg: false,
      v_settings_dlg: false,
      lang_value: 'zh-cn',
      lang_options: [
        {label: '简体中文', value: 'zh-cn'},
        {label: '繁體中文', value: 'zh-tw'},
      ],
      user_info: {},
      others: [],
      rel_dept_product: {}
    }
  },
  created() {
    EventBus.$on('add-brief', this.addBrief);
  },
  beforeDestroy() {
    EventBus.$off('add-brief', this.addBrief);
  },
  computed: {
    brief_list_c: function () {
      let me = this
      let brief_list_t = JSON.parse(JSON.stringify(me.brief_list))
      if (me.$i18n.locale === 'zh-tw') {
        brief_list_t.forEach(item => {
          item.briefName = me.translation(item.briefName);
        });
      }
      return brief_list_t
    }
  },
  watch: {
    lang_value: function (newVal) {
      this.$i18n.locale = newVal
      localStorage.setItem("locale", newVal)
    },
    '$route': function (to) {
      let brief_id = to.query['brief_id']
      this.current_brief_id = brief_id ? brief_id : 0
      this.brief_list.forEach(item => {
        if (brief_id && item.id == brief_id) {
          item.is_active = true;
        } else {
          item.is_active = false;
        }
      });
    }
  },
  mounted() {
    let locale = localStorage.getItem("locale")
    if (locale) {
      this.$i18n.locale = locale
      this.lang_value = locale
    }
    let user_info = JSON.parse(localStorage.getItem("user_info"))
    this.user_info = user_info
    this.loadBriefList()
  },
  methods: {
    addBrief(data) {
      this.brief_list.unshift(data)
      this.highLightBrief(0)
    },
    loadBriefList() {
      let me = this
      this.axios.get("/brief/list").then((res) => {
        let brief_id = me.$route.query['brief_id']
        res.data.forEach(item => {
          if (brief_id && item.id == brief_id) {
            item.is_active = true;
          } else {
            item.is_active = false;
          }
        });
        me.brief_list = res.data
        me.current_brief_id = brief_id ? brief_id : 0
      })
    },
    reset() {
      this.$router.push("/home/workspace")
    },
    highLightBrief(index) {
      this.brief_list.forEach(item => {
        item.is_active = false;
      });
      this.brief_list[index]['is_active'] = true
    },
    selectBrief(index) {
      this.current_brief_id = this.brief_list[index].id
      this.highLightBrief(index)
      this.$router.push({
        path: "/home/workspace",
        query: {
          brief_id: this.current_brief_id
        }
      })
    },
    openRenameChatDialog: function (briefItem) {
      let me = this
      let briefName = briefItem.briefName
      if (me.$i18n.locale === 'zh-tw') {
        briefName = me.translation(briefItem.briefName)
      }
      this.$prompt(this.$t('home.modelmessgage01'), this.$t('home.modeltitle01'), {
        confirmButtonText: this.$t('home.modelok'),
        cancelButtonText: this.$t('home.modelcancel'),
        inputValue: briefName,
        closeOnClickModal: false
      }).then(({value}) => {
        this.axios.post('/brief/rename', {briefId: briefItem.id, briefName: value}).then(() => {
          for (let item in me.brief_list) {
            if (me.brief_list[item].id === briefItem.id) {
              me.brief_list[item].briefName = value
            }
          }
        })
      })
    },
    openDeleteChatDialog: function (brief_id) {
      let me = this
      this.$confirm(this.$t('home.modelmessgage02'), this.$t('home.modeltitle02'), {
        confirmButtonText: this.$t('home.modelok'),
        cancelButtonText: this.$t('home.modelcancel'),
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        me.axios.post('/brief/delete', {briefId: brief_id}).then(() => {
          me.loadBriefList()
          me.reset()
        })
      })
    },
    deleteAll: function () {
      let me = this
      this.$confirm("您确认删除所有聊天吗", this.$t('home.modeltitle02'), {
        confirmButtonText: this.$t('home.modelok'),
        cancelButtonText: this.$t('home.modelcancel'),
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        me.axios.post('/brief/deleteAll').then(() => {
          me.loadBriefList()
          me.reset()
        })
      })
    },
    logout: function () {
      localStorage.removeItem("token")
      localStorage.removeItem("user_info")
      this.$router.push("/intro")
    },
    dropDownClick: function (command) {
      let me = this
      if (command == "course") {
        me.axios.get("/product/dept/get").then((res) => {
          me.rel_dept_product = res.data
          me.v_course_dlg = true
        })
      } else if (command == "team") {
        this.$router.push({
          path: "/home/usage",
          query: {
            users: "others"
          }

        })
      } else if (command == "usage") {
        this.$router.push({
          path: "/home/usage",
          query: {
            users: "self"
          }
        })
      } else if (command == "settings") {
        me.axios.get("/settings/others").then((res) => {
          me.others = res.data
          me.v_settings_dlg = true
        })
      } else if (command == "logout") {
        this.logout()
      }
    },

  }
}
</script>

<style scoped>

.container {
  width: 100%;
  height: 100%;
}

.left-brief-list {
  width: 2.8rem !important;
  background-color: #98A99B;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;

}

.logo-wrap {
  display: flex;
  align-items: center;
}

.logo-sm {
  width: 0.5rem;
  height: 0.5rem;
}

.logo-title {
  font-size: 0.24rem;
  color: #ffffff;
  margin-left: 0.2rem;
}

.new-brief {
  font-size: 0.18rem;
  color: #ffffff;
  cursor: pointer;
  margin-top: 0.4rem;
  font-weight: lighter;
}

.brief-list {
  margin-top: 0.4rem;
  flex: 1;
}

.brief-item {
  height: 0.36rem;
  font-size: 0.18rem;
  color: #ffffff;
  cursor: pointer;
  font-weight: lighter;
  display: flex;
  align-items: center;
}

.brief-item.active {
  font-weight: normal;
}

.brief-item i {
  margin-left: 0.2rem;
}

.brief-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mr20 {
  margin-right: 0.2rem;
}

.mt20 {
  margin-top: 0.2rem;
}

.mt40 {
  margin-top: 0.4rem;
}

.my-footer {
  font-size: 0.18rem;
  color: #FFFBEB;
}

.username-dropdown {
  font-size: 0.18rem;
  color: #FFFBEB;
  cursor: pointer;
}

.txt-right {
  text-align: right;
}

.fw-normal {
  font-weight: normal;
}

.ft18 {
  font-size: 0.18rem;
}

/deep/ .course-modal .el-dialog .el-dialog__header {
  font-size: 18px;
}

/deep/ .settings-modal .el-dialog .el-dialog__header {
  font-size: 18px;
}

.bg-grey {
  border: 0;
  height: 1px;
  background: #c9c9c9;
}
</style>
